import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        Chocomenta © {new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
