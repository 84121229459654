import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import React from "react"

const About = ({ img }) => {
  return (
    <section id="about">
      <h2>Contatti</h2>
      <div className="about-card">
        <div className="about-img--box">
          <Img fluid={img.childImageSharp.fluid} className="about-img" />
        </div>
        <div className="contacts">
          <div>E noi siamo Denise e Lucia!</div>
          <div>Per ordini o prezzi contattaci.</div>
          <div className="contacts--name">Denise Carrubba</div>
          <a href="https://wa.me/393405643914" target="blank">
            3405643914
          </a>
          <div className="contacts--name">Lucia Gallo</div>
          <a href="https://wa.me/393498079499" target="blank">
            3498079499
          </a>
          <div className="contacts--icons-box">
            <a
              href="mailto:ciao@chocomenta.it"
              className="contact--icon"
              target="blank"
            >
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/chocomentamidori/"
              target="blank"
            >
              <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
