import Img from "gatsby-image"
import React from "react"

const Paper = ({ img }) => {
  return (
    <section id="materials">
      <h2>Materiali</h2>
      <div className="section">
        <div className="classic-text-content">
          <h3>La carta</h3>
          <p>
            I nostri refills sono creati a mano. Usiamo la miglior carta sul
            mercato per garantirti una piacevole esperienza di scrittura. Il
            refill white con carta bianca da 80gr è spettacolare per i tuoi
            disegni o sketch. La carta puntinata da 70gr è l'ideale per le tue
            liste, agende o per la scrittura ed è studiata per essere efficiente
            con le stilografiche.
          </p>
        </div>
        <Img fluid={img.childImageSharp.fluid} className="iphone" />
      </div>
    </section>
  )
}

export default Paper
