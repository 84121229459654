import BackgroundImage from "gatsby-background-image"
import React from "react"

const Hero = ({ img }) => {
  return (
    <BackgroundImage fluid={img.childImageSharp.fluid} Tag="header">
      <div className="overlay" />
      <h1>Chocomenta midori</h1>
      <div className="subtext">Per chi scrive.</div>
      <div className="subtext">Per chi disegna.</div>
      <div className="subtext">Per te.</div>
    </BackgroundImage>
  )
}

export default Hero
