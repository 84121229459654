import Img from "gatsby-image"
import React from "react"

const Iphone = ({ img }) => {
  return (
    <section>
      <div className="section">
        <div className="classic-text-content">
          <h3>Un compagno di vita</h3>
          <p>
            Per le sue caratteristiche stilose e minimali, il midori potrà
            essere la tua agenda, il tuo bullet journal, il tuo diario personale
            o dove fai ordine mentale. Sarà un confidente, uno psicologo, un
            coach. La scrittura a mano non è mai stata così utile e divertente!
          </p>
        </div>
        <Img fluid={img.childImageSharp.fluid} className="iphone" />
      </div>
    </section>
  )
}

export default Iphone
