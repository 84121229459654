import Img from "gatsby-image"
import React from "react"

const Midori = ({ img }) => {
  return (
    <section id="midori">
      <div className="section">
        <Img fluid={img.childImageSharp.fluid} className="tris" />
        <div className="midori-text-content">
          <h3>Cos'è un midori</h3>
          <p>
            Noto come Traveler Notebook, il midori nasce in Giappone. E si sa:
            chi viaggia non conosce limiti. Ecco perché non è solo un semplice
            raccoglitore. La sua copertina in pelle lo rende resistente e
            durevole per qualsiasi utilizzo. Puoi personalizzarlo come vuoi e
            questo lo rende unico.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Midori
