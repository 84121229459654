import Img from "gatsby-image"
import React from "react"

const Customization = ({ imgs }) => {
  const leather_colors = [
    {
      name: "Mirto",
      img: "mirto.png",
    },
    {
      name: "Liquirizia",
      img: "liquirizia.png",
    },
    {
      name: "Cioccolato",
      img: "choco.png",
    },
    {
      name: "Cappuccino",
      img: "cappuccino.png",
    },
    {
      name: "Melograno",
      img: "melograno.png",
    },
    {
      name: "Salvia",
      img: "salvia.png",
    },
  ]

  const bands_colors = [
    {
      name: "Arancione",
      img: "elastico_arancio.jpg",
    },
    {
      name: "Menta",
      img: "elastico_menta.jpg",
    },
    {
      name: "Rosso",
      img: "elastico_rosso.jpg",
    },
    {
      name: "Turchese",
      img: "elastico_turchese.jpg",
    },
  ]

  const accessories = [
    {
      name: "Incisioni",
      img: "iniziali.JPG",
    },
    {
      name: "Refills",
      img: "refills.png",
    },
    {
      name: "Copertina porta carte",
      img: "copertina.png",
    },
    {
      name: "Fascia di chiusura",
      img: "chiusura.JPG",
    },
  ]

  return (
    <section id="customizations">
      <h2>Personalizzazioni</h2>
      <h3>Colore della pelle</h3>
      <div className="grid three">
        {leather_colors.map((l, i) => {
          return imgs.edges.map((img, i) => {
            const imageObj = img.node.childImageSharp.fluid
            if (imageObj.originalName === l.img) {
              return (
                <div className="color-card" key={i}>
                  <Img fluid={imageObj} className="leather-color-img" />
                  <div className="title">{l.name}</div>
                </div>
              )
            }
          })
        })}
      </div>
      <h3 className="band-color-title">Colore dell'elastico</h3>
      <div className="grid four">
        {bands_colors.map((l, i) => {
          return imgs.edges.map((img, i) => {
            const imageObj = img.node.childImageSharp.fluid
            if (imageObj.originalName === l.img) {
              return (
                <div className="bands-card" key={i}>
                  <Img fluid={imageObj} />
                  <div className="title">{l.name}</div>
                </div>
              )
            }
          })
        })}
      </div>
      <h3 className="accessories-title">Accessori</h3>
      <div className="grid four">
        {accessories.map((a, i) => {
          return imgs.edges.map((img, i) => {
            const imageObj = img.node.childImageSharp.fluid
            if (imageObj.originalName === a.img) {
              return (
                <div className="bands-card" key={i}>
                  <Img fluid={imageObj} />
                  <div className="title">{a.name}</div>
                </div>
              )
            }
          })
        })}
      </div>
    </section>
  )
}

export default Customization
