import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const ToTop = ({ handleClick }) => (
  <button className="to-top-button" onClick={handleClick}>
    <FontAwesomeIcon icon={faChevronUp} className="icon-class" />
  </button>
)

export default ToTop
