import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import About from "../components/About"
import Customization from "../components/Customization"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Iphone from "../components/Iphone"
import Leather from "../components/Leather"
import Midori from "../components/Midori"
import Nav from "../components/Nav"
import Paper from "../components/Paper"
import SEO from "../components/Seo"
import ToTop from "../components/ToTop"
import "../styles/style.scss"
// import Offers from "../components/Offers"

export const Home = ({ data }) => {
  const [toTopVisible, setToTopVisible] = useState(false)
  const {
    logo,
    hero,
    dot,
    tris,
    iphone,
    customs,
    leather,
    paper,
    about,
    site,
  } = data

  const gotoTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      setToTopVisible(true)
    } else {
      setToTopVisible(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", scrollFunction, true)
      return () => window.removeEventListener("scroll", scrollFunction, true)
    }
  }, [])

  return (
    <React.Fragment>
      <SEO site={site} />
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${dot.childImageSharp.fixed.src})`,
        }}
      >
        <Nav logo={logo} />
        <Hero img={hero} />
        <main>
          <div className="container">
            {/* <Offers /> */}
            <Midori img={tris} />
            <Iphone img={iphone} />
            <Paper img={paper} />
            <Leather img={leather} />
            <Customization imgs={customs} />
            <About img={about} />
            {/* <ImageSeparator img={violin} /> */}
          </div>
        </main>
        <Footer />
        {toTopVisible && <ToTop handleClick={() => gotoTop()} />}
      </div>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query IndexPageTemplate {
    site {
      siteMetadata {
        title
        description
        author
        titleTemplate
        keywords
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dot: file(relativePath: { eq: "dot.jpg" }) {
      childImageSharp {
        fixed(width: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tris: file(relativePath: { eq: "tris.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphone: file(relativePath: { eq: "iphone.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    about: file(relativePath: { eq: "noi.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paper: file(relativePath: { eq: "carta.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leather: file(relativePath: { eq: "pelle.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customs: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "customs" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
              originalName
              src
            }
          }
        }
      }
    }
  }
`

export default Home
