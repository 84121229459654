import Img from "gatsby-image"
import React from "react"

const Leather = ({ img }) => {
  return (
    <section>
      <div className="section">
        <Img fluid={img.childImageSharp.fluid} className="tris" />
        <div className="midori-text-content">
          <h3>La pelle</h3>
          <p>
            Direttamente dalla Toscana, terra d'eccellenza per la pelle,
            utilizziamo pelle in concia vegetale di alta qualità da 2mm.
            Procuriamo il grezzo che poi lavoriamo e coloriamo per portarlo a
            essere un midori piacevole al tatto e resistente.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Leather
