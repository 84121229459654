import Img from "gatsby-image"
import React from "react"

const Nav = ({ logo }) => {
  return (
    <nav>
      <div id="menu" className="container">
        <div>
          <Img fixed={logo.childImageSharp.fixed} alt="Chocomenta logo" />
        </div>
        <div className="items">
          <a href="#midori" className="item">
            Midori
          </a>
          <a href="#materials" className="item">
            Materiali
          </a>
          <a href="#customizations" className="item">
            Personalizzazioni
          </a>
          <a href="#about" className="item">
            Contatti
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Nav
